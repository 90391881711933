
import { computed, defineComponent, ref, toRefs, watch } from "vue";
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import { Field, Form } from "vee-validate";
import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"
import * as Validations from "yup";
import { getModule } from "vuex-module-decorators";
import NewDeliveryModule from "@/store/modules/deliverysale/modules/new-delivery";
import { hideModalById } from "@/core/helpers/dom";
import ViewTableDeliveryModule from "@/store/modules/deliverysale/modules/table-view-table";

export default defineComponent({
  name: "new-event-modal",
  components: {
    SelectFilterRemote
   ,Field
   ,Form
   ,DynamicContentModal
  },
  props: {
    saleid: {
            type: String,
            required: false,
            default: () => ""
        },
    SaleDelivery: {
        type: Object,
        required: false,
        default: () => null
    }
  },
  setup(props, _) {
    const { SaleDelivery } = toRefs(props)
    const moduleNewDelivery = getModule(NewDeliveryModule);
    const moduleDelivery = getModule(NewDeliveryModule);
    const moduleTable = getModule(ViewTableDeliveryModule);
    const formAdd = ref();

    const validationSchema = Validations.object().shape({
              agenteId: Validations.string().nullable().required().label("agenteId"),
              dateDeliveries: Validations.string().nullable().required().label("dateDeliveries"),
              timeDeliveries: Validations.string().nullable().required().label("timeDeliveries"),
              receiver: Validations.string().nullable().default(null).required().label("receiver"),
              observation: Validations.string().nullable().default(null).required().label("observation")
    });

    const searchSales = (filterName: string) => {
      moduleDelivery.GET_DELIVERY_PLAN(filterName);
    }

    const searchAgent = (filterName: string) => {
      moduleDelivery.GET_AGENTS(filterName);
    }

    const ItemsAgents = computed(() => moduleDelivery.getAgentsOptions);
    const ItemsSales = computed(() => moduleDelivery.getSalesToPlanOptions);
    const loadingSalesCombo = computed(() => moduleDelivery.isLoadingSales);
    const loadingAgentsCombo = computed(() => moduleDelivery.isLoadingAgents);


    const submitForm = async (data) => {

        var fecha = new Date(data.dateDeliveries + "T" + data.timeDeliveries + "z");
     
       await moduleNewDelivery.SAVE_NEW_DELIVERY({
          id: props.saleid,
          observation: data.observation,
          receiver: data.receiver,
          saleDeliveryPlanAt: fecha.toISOString(),
          userAgentId: data.agenteId
        }).then(() => {
          
          // formAdd.value.resetForm();
        });

        // formAdd.value.resetForm();
        hideModalById("#AddDeliveriesrequest");
        moduleTable.SEARCH_TABLE_DATA();
    }

    watch(SaleDelivery, (nValue, oValue) => {
        if (nValue) {
            formAdd.value.resetForm();
            formAdd.value.setFieldValue('receiver', props.SaleDelivery.receiver);
            formAdd.value.setFieldValue('observation', props.SaleDelivery.observation);
        }
    });

    return {
      validationSchema,
      formAdd,
      moduleTable,
      submitForm
      , searchSales
      , searchAgent

      , loadingSalesCombo
      , loadingAgentsCombo
      , ItemsAgents
      , ItemsSales
    };
  },
});
