import GeneralFiltersTable from "@/models/general/Paginations/Filters/GeneralFiltersTable";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from '@/store';
import TablePaginationRequest from "@/models/general/TablePaginationRequest";
import TableDeliveryState from "../../models/TableDeliveryState";
import DeliveryRow from "@/models/delivery/DeliveryRows";
import DeliveryService from "@/core/services/delivery/DeliveryService";
import DeliveryTableService from "@/core/services/delivery/interfaces/DeliveryTableService";
import Modules from "@/store/enums/Modules";
import DeliveryFilter from "@/models/delivery/DeliveryFilter";
import { SaveValuesToStore } from "@/core/shared/AssingProps";
import DeliveryViewTableState from "../../models/DeliveryViewTableState";
import DeliveryViewTableRow from "@/models/delivery/DeliveryViewTableRows";
import DeliveryViewTableFilter from "@/models/delivery/DeliveryViewTableFilter";
import { Mutations } from '../../../../commons/MutationsTable';
import PaginationRequest from "@/models/general/PaginationRequest";
import { Actions } from "@/store/commons/ActionsTable";

@Module({dynamic: true, store, namespaced: true, name: Modules.DeliveryViewTableModule})
export default class ViewTableDeliveryModule extends VuexModule implements DeliveryViewTableState {

    records = [] as DeliveryViewTableRow[];
    totalRecords = 0;
    filters = {
        driverid: ''
        , status: "-1"
        , invoice: ""
        , PlanDateEnd: ""
        , PlanDateInit: ""
    } as DeliveryViewTableFilter;
    pagination = {
        currentPage: 1
        , pageSize: 10 //default
    } as PaginationRequest;
    loading = false;

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: DeliveryViewTableRow[]){
        this.records = table;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: unknown){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: unknown){
        SaveValuesToStore(this.filters, filters);
    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }

    /**
     * Se obtienen los datos de la tabla de clientes mediante la paginación
     * @param paginator paginador de las notas
     */
     @Action
     async [Actions.SEARCH_TABLE_DATA](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
         const resp = (await service.getTableView(this.pagination, this.filters)
         .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ??
             {
                 data: [] as DeliveryViewTableFilter[]
                , totalPages: 0
                , currentPage: this.pagination.currentPage
             }
 
         this.context.commit(Mutations.SET_TABLE_DATA, resp.data);
         this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
         this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalRecords);
    }

    /**
       * Realiza un busqueda con la nueva paginación
       * @param pagination paginación de la tabla
       */
     @Action
    async [Actions.CHANGE_PAGE](pagination: unknown){
          this.context.commit(Mutations.SET_PAGINATION, pagination);
          this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
    /**
     * Filtros que se aplican y despues se hace la busqueda de los datos
     * @param filters filtros nuevos
     */
    @Action
    async [Actions.UPDATE_FILTERS](filters: unknown){
        //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
        this.context.commit(Mutations.SET_FILTERS, filters);
        this.context.commit(Mutations.SET_PAGINATION, {
          currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
      });
        this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
}

/**
 * Servicio del store (no locolocamos adentro por que seria una propiedad del store)
 */
 const service =  new DeliveryService() as DeliveryTableService;