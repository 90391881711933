import Actions from './Actions';
import Modules from "@/store/enums/Modules";
import store from "@/store";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Mutations } from './Mutations';
import NewDeliveryState from '../../models/NewDeliveryState';
import NewDelivery from '@/models/delivery/NewDelivery';
import DeliveryService from '@/core/services/delivery/DeliveryService';
import DeliveryToPlan from '@/models/delivery/DeliveryToPlan';
import OptionSelect from '@/models/shared/OptionSelect';


@Module({dynamic: true, store, namespaced: true, name: Modules.NewDeliveryModule})
export default class NewDeliveryModule extends VuexModule implements NewDeliveryState {
    
    /**
     * Los valores iniciales son los valores del formulario, se usan para las ediciones
    */
   initialValues = formDefaultValues
   loading = false;
   isLoadingAgents = false;
   isLoadingSales = false;
   idSelected = '';
   selects = { 
        agents: [] as OptionSelect[],
        salesDeliveryToPlan: [] as DeliveryToPlan[]
    };

    get getValuesForm(): NewDelivery {
        return this.initialValues;
    }

    get getSalesToPlanOptions(): Array<DeliveryToPlan> {
        return this.selects.salesDeliveryToPlan;
    }

    get getAgentsOptions(): Array<OptionSelect> {
        return this.selects.agents;
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }

    @Mutation
    [Mutations.SET_LOADING_AGENTS](value: boolean){
        this.isLoadingAgents = value;
    }

    @Mutation
    [Mutations.SET_LOADING_DELEVERY](value: boolean){
        this.isLoadingSales = value;
    }

    @Mutation
    [Mutations.SET_LOADINGS](values: any){
        this.loading = values;
    }

    @Mutation
    [Mutations.SET_DATA_DELIVERY](values: NewDelivery){
        this.initialValues = values;
    }

    @Mutation
    [Mutations.SET_DELIVERY_PLAN](values: DeliveryToPlan[]){
        this.selects.salesDeliveryToPlan = values;
    }

    @Mutation
    [Mutations.SET_AGENTS](values: OptionSelect[]){
        this.selects.agents = values;
    }

    @Action
    async [Actions.SAVE_NEW_DELIVERY](form: NewDelivery){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.updateDelivery(form.id, form)
            .then((resp) => {
                return resp.data;
            })
            .finally(() => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
            })
    }

    @Action
    async [Actions.UPDATE_INFO_DELIVERY](form: NewDelivery){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.update(this.idSelected, form)
            .then((resp) => {

                return resp.data;
            })
            .finally(() => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
            })
    }

    @Action({ commit: Mutations.SET_DATA_DELIVERY})
    async [Actions.GET_INFO_DELIVERY](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return service.getInfo(id)
            .then((resp) => {
                return resp.data;
            })
            .finally(() => {
                this.context.commit(Mutations.SET_VALUE_LOADING, false)
            })
    }


    @Action({ commit: Mutations.SET_DELIVERY_PLAN})
    async [Actions.GET_DELIVERY_PLAN](filterName: string){
        this.context.commit(Mutations.SET_LOADING_DELEVERY, true);
        return service.searchSalesToPlanOptions(filterName)
            .then((resp) => {
                return resp.data;
            })
            .finally(() => {
                this.context.commit(Mutations.SET_LOADING_DELEVERY, false)
            })
    }

    @Action({ commit: Mutations.SET_AGENTS})
    async [Actions.GET_AGENTS](filterName: string){
        this.context.commit(Mutations.SET_LOADING_AGENTS, true);
        return service.searchAgentsOptions(filterName)
            .then((resp) => {
                return resp.data;
            })
            .finally(() => {
                this.context.commit(Mutations.SET_LOADING_AGENTS, false)
            })
    }
}
const service =  new DeliveryService();

/**
 * Valores por defecto del formulario
 */
 const formDefaultValues = {
    id: "",
    userAgentId: "",
    observation: "",
    receiver: "",
    saleDeliveryPlanAt: ""
} as NewDelivery;

